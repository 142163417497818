<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>当月累计进度</div>
    </div>
    <div
      class="select-card-bottom"
      v-watermark="{ text: text, textColor: 'rgba(180, 180, 180, 0.3)' }"
    >
      <div id="myChart" :style="{ width: '100%', height: '450px' }"></div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  props: ["transfer"],
  data() {
    return {
      resultData: [],
      text: "",
    };
  },
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.text = JSON.parse(sessionStorage.getItem("userInfo")).name;
    }
    this.querySelect();
  },
  mounted() {
    // this.drawLine();
  },
  methods: {
    // 查询数据
    querySelect() {
      this.$store.dispatch("app/setLoading", true);
      console.log(this.transfer)
      // 数据请求
      this.axios
        .post("HOME_DEPTMONTHTOP", this.transfer)
        .then((result) => {
          console.log("查询返回", result);
          this.resultData = result.data;
          // this.$store.dispatch('data/setTotalData', result.data)
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
          this.drawLine();
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      var option;

      let xAxisData = [];
      let data1 = [];
      let data2 = [];
      let data3 = [];

      for (let i of this.resultData) {
        xAxisData.push(i.deptName);
        // data1.push(+(Math.random() * 2).toFixed(2));
        // data2.push(+(Math.random() * 5).toFixed(2));
        // data3.push(+(Math.random() + 0.3).toFixed(2));

        data1.push(this.$keepTwo(i.saleTarget));
        data2.push(this.$keepTwo(i.saleTotal));
        data3.push(this.$keepTwo(i.saleRadio));
      }
      let dataTotal = [...data1, ...data2];
      // console.log(dataTotal)
      let saleRadios = [];
      option = {
        legend: {
          data: ["销售目标", "销售额", "进度"],
          itemWidth: 12,
          itemHeight: 12,
          type: "scroll",
          orient: "horizontal",
          itemGap: 24,
          icon: "roundRect",
          left: "left",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          padding: 12,
          extraCssText: "box-shadow: 0px 2px 12px 0px rgba(64, 98, 117, 0.1);",
          textStyle: {
            color: "#262626", //设置文字颜色
            fontSize: 12,
          },
          backgroundColor: "#FFFFFF", //设置背景颜色
          borderColor: "#FFFFFF", //设置边框颜色
          formatter(params) {
            // console.log(params);
            let html = "";
            for (let i in params) {
              html += `<span style="display:inline-block;width:10px;height:10px;background:${
                params[i].color
              };
border-radius: 2px;margin-right:4px;"></span><span>${
                params[i].seriesName
              }:</span><p style="min-width:20px;margin-right:4px;text-align:right;display:inline-block;float:right">${
                params[i].value ? params[i].value : "--"
              }<span style="margin-left:4px;display:inline-block;">${
                params[i].seriesName == "进度" ? "%" : "万"
              }</span></p><br/>`;
            }
            // 返回值就是html代码可以使用标签
            return html;
          },
        },
        xAxis: {
          data: xAxisData,
          type: "category",
          itemStyle: {
            color: "#8C8C8C",
          },
          axisLine: { onZero: false },
          splitLine: { show: false },
          splitArea: { show: false },
          axisTick: {
            show: false, //y轴坐标点消失
          },
          axisLine: {
            show: false, //y轴线消失
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8C8C8C",
            },
          },
        },
        yAxis: [
          {
            // type: "category",
            splitNumber: 5,
            interval: Math.ceil(Math.ceil(Math.max(...dataTotal)) / 5),
            max: Math.ceil(Math.ceil(Math.max(...dataTotal)) / 5) * 5,
            min:Math.ceil(Math.ceil(Math.min(...dataTotal)) / 5) * 5,
            axisTick: {
              inside: false,
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#8C8C8C",
              },
            },
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLine: {
              show: false, //y轴线消失
            },
          },
          {
            name: "",
            type: "value",
            position: "right",
            formatter: "{value}%",
            interval: Math.ceil(Math.ceil(Math.max(...data3)) / 5),
            max: Math.ceil(Math.ceil(Math.max(...data3)) / 5) * 5,
            min:Math.ceil(Math.ceil(Math.min(...data3)) / 5) * 5,
            nameTextStyle: { fontSize: 12, color: "#8C8C8C",align: "left",width:'60%' },
            itemStyle: {
              color: "#ffd07a",
            },
            axisTick: {
              inside: false,
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#8C8C8C",
              },
            },
            axisTick: {
              show: false, //y轴坐标点消失
            },
            axisLine: {
              show: false, //y轴线消失
            },
          },
        ],
        grid: {
          bottom: "24",
          left: "10",
          right: "10",
          top: "48",
          width: "auto",
          height: "auto",
          containLabel: true,
        },
        series: [
          {
            name: "销售目标",
            type: "bar",
            stack: "one",
            data: data1,
            backgroundStyle: {
              color: "#6080EC",
            },
            itemStyle: {
              color: "#6080EC",
              barBorderRadius: [74, 74, 0, 0],
            },
            barMaxWidth: 30,
          },
          {
            name: "销售额",
            type: "bar",
            stack: "two",
            data: data2,
            barMaxWidth: 30,
            backgroundStyle: {
              color: "#36BFCD",
            },
            itemStyle: {
              barBorderRadius: [74, 74, 0, 0],
              color: "#36BFCD",
            },
          },
          {
            name: "进度",
            type: "bar",
            stack: "three",
            data: data3,
            barMaxWidth: 30,
            backgroundStyle: {
              color: "#23C782",
            },
            yAxisIndex: 1,
            itemStyle: {
              color: "#23C782",
              barBorderRadius: [74, 74, 0, 0],
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>


<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .select-card-bottom {
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    width: 100%;
    min-height: 500px;
  }
}
</style>