<template>
  <div class="content">
    <!-- 交易概览 -->
    <overview @transfer="getUser"></overview>
    <Totalview :transfer = "transfer" v-if='transfer.dateType' :key="random"/>
    <CurrentMonView :transfer = "transfer" v-if='transfer.dateType' :key="random"/>
    <AreaView :date = "transfer" v-if='transfer.dateType' :key="random+'h'"/>
    <div style="height:20px"></div>
  </div>
</template>

<script>
import Overview from "./components/overview";
import Totalview from "./components/totalview";
import CurrentMonView from "./components/currentmonview";
import AreaView from "./components/areaview";

export default {
  name: "Home",
  components: {
    Overview,
    Totalview,
    CurrentMonView,
    AreaView,
  },
  data() {
    return {
      boxHeight: 0,
      transfer:{},
      random:0,
    };
  },
  mounted() {
    this.boxHeight = document.body.clientHeight - 100 + "px";
  },
  methods:{
    getUser(msg){
       this.transfer= msg
       console.log(this.transfer)
       this.random = Math.random()
     }
  }
};
</script>

<style scoped>
.content {
  width: 100%;
  height: 100vh;
}
.card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pic-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-pic {
  width: 600px;
  height: 345px;
}
.title {
  /* font-weight: bold; */
  /* font-size: 25px; */
  color: #262626;
  letter-spacing: 3px;
  margin-top: 50px;
}
</style>