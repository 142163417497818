<template>
  <div class="select-card">
    <div class="select-card-bottom">
      <div class="select-card-bottom-left">
        <div class="select-card-top" style="border: none">
          <div class="left flexBox">大区排名</div>
          <div
            class="right flexBox"
            v-if="region.sort == 'desc'"
            @click="region_sort('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right" v-else @click="region_sort('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
        </div>
        <div class="item" v-for="(item, index) in testData" :key="index">
          <div class="item-index">{{item.seqNo }}</div>
          <div class="item-title">{{ item.departName }}</div>
          <div class="item-li">
            <el-progress
              class="count_pro"
              :percentage="item.count?item.count:0"
              :stroke-width="18"
              :text-inside="true"
              :color="customColor"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="item-last">
            <p class="one" v-if="index == 0">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="two" v-if="index == 1">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="three" v-if="index == 2">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="four" v-if="index > 2">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="small">{{ item.userName }}</p>
          </div>
        </div>
        <div class="select-card-top" style="border: none">
          <div class="right" style="width:100%;float:right;">
            <el-button
              type="primary"
              @click="more(1)"
              plain
              size="small"
              style="border: none"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
      <div class="block"></div>
      <div class="select-card-bottom-right">
        <div class="select-card-top" style="border: none">
          <div class="flexBox">地区排名</div>
          <div
            class="right flexBox"
            v-if="dept.sort == 'desc'"
            @click="dept_sort('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right" v-else @click="dept_sort('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
        </div>
        <div class="item" v-for="(item, index) in testData2" :key="index">
          <div class="item-index">{{ item.seqNo }}</div>
          <div class="item-title">{{ item.departName }}</div>
          <div class="item-li">
            <el-progress
              :percentage="item.count?item.count:0"
              :stroke-width="18"
              :text-inside="true"
              color="#23C782"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="item-last">
            <p class="one" v-if="index == 0">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="two" v-if="index == 1">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="three" v-if="index == 2">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="four" v-if="index > 2">
              {{ item.taxableAmount }}<span>万</span>
            </p>
            <p class="small">{{ item.userName }}</p>
          </div>
        </div>
        <div class="select-card-top" style="border: none">
          <div class="right" style="width:100%;float:right;">
            <el-button
              type="primary"
              @click="more(2)"
              plain
              size="small"
              style="border: none;"
              >查看更多</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="60%" :show-close="false" style="border-radius: 15px;">
      <div class="select-card-bottom-left">
        <div class="select-card-top" style="border: none">
          <div class="left flexBox">{{ moreData.title }}</div>
          <div
            class="right"
            v-if="moreData.sort == 'desc'"
            @click="sortNum('asc')"
          >
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-drop" height="26" width="26" />
            </svg>
            <span>从高到低</span>
          </div>
          <div class="right flexBox" v-else @click="sortNum('desc')">
            <svg class="svgClass" height="16" width="16">
              <use xlink:href="#icon-rise" height="26" width="26" />
            </svg>
            <span>从低到高</span>
          </div>
          <div class="close" @click="dialogVisible = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="item" v-for="(item, index) in moreDatas" :key="index">
          <div class="item-index">{{ item.seqNo}}</div>
          <div class="item-title">{{ item.departName }}</div>
          <div class="item-li">
            <el-progress
             :percentage="item.count?item.count:0"
              :stroke-width="18"
              :text-inside="true"
              :color="moreData.color"
              :show-text="false"
            ></el-progress>
          </div>
          <div class="item-last">
            <p class="one" v-if="index == 0">{{ item.taxableAmount }}万</p>
            <p class="two" v-if="index == 1">{{ item.taxableAmount }}万</p>
            <p class="three" v-if="index == 2">{{ item.taxableAmount }}万</p>
            <p class="four" v-if="index > 2">{{ item.taxableAmount }}万</p>
            <p class="small">{{ item.userName }}</p>
          </div>
        </div>
        <div class="select-card-top">
          <div style="margin: auto">
            <el-pagination
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :current-page="moreData.currentPage"
              :page-size="10"
              :total="moreData.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["date"],
  data() {
    return {
      customColor: "#5E7BBF",
      customColor2: "#23C782",
      dialogVisible: false,
      timeType: 1,
      startTime: "",
      personnelIdentity:"",//身份
      region: {
        pageSize: 6, //每页行数
        pageNum: 1, //页码
        sort: "desc",
      },
      dept: {
        pageSize: 6, //每页行数
        pageNum: 1, //页码
        sort: "desc",
      },
      maxprecent: "",
      maxprecent2: "",
      testData: [],
      testData2: [],
      moreDatas: [],
      dialogTitle: "大区排名",
      moreData: {
        title: "",
        total: 0,
        currentPage: 1,
        sort: "desc",
        color: "#5E7BBF",
        type: 1,
        maxprecent: 0,
      },
      moreTotal: [],
    };
  },
  mounted() {
    console.log(222222222222222);

    this.timeType = this.date.dateType;
    this.startTime = this.date.startTime;
    this.personnelIdentity = this.date.personnelIdentity;
    this.querySelect();
    this.querySelectDept();
  },
  methods: {
    region_sort(val) {
      this.region.sort = val;
      this.querySelect();
    },
    dept_sort(val) {
      this.dept.sort = val;
      this.querySelectDept();
    },
    sortNum(val) {
      console.log(val);
      this.moreData.sort = val;
      this.moreSelect(this.moreData.type);
    },
    more(type) {
      this.moreData.currentPage = 1;
      this.moreData.sort = "desc";
      if (type == 1) {
        this.moreData.title = "大区排名";
        this.moreData.color = "#5E7BBF";
        this.moreData.type = 1;
      } else {
        this.moreData.title = "地区排名";
        this.moreData.color = "#23C782";
        this.moreData.type = 2;
      }
      this.moreSelect(this.moreData.type);
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      console.log(val);
      this.moreData.currentPage = val;
      this.pageList();
      // this.moreSelect(this.moreData.type);
    },
    moreSelect(type) {
      this.$store.dispatch("app/setLoading", true);
      // 数据请求
      let params = {
        dateType: this.timeType,
        pageSize: 10, //每页行数
        pageNum: this.moreData.currentPage, //页码
        sort: this.moreData.sort,
        startTime: this.startTime,
        personnelIdentity:this.personnelIdentity
      };
      let url = "HOME_REGIONTOP";
      if (type == 2) {
        url = "HOME_DEPT4TOP";
      }

      this.axios
        .post(url, params)
        .then((result) => {
          console.log("查询返回", result);
          console.log(result.data);
          let list = [];
          if (result.data.length > 0) {
            console.log(result.data);
            result.data.forEach((element, index) => {
              if (
                index == 0 &&
                this.moreData.currentPage == 1 &&
                this.moreData.sort == "desc"
              ) {
                this.maxprecent = element.taxableAmount;
              }
              if(element.taxableAmount>0&&this.maxprecent){
                element.count =
                (element.taxableAmount / this.maxprecent).toFixed(2) * 100;
              }else{
                 element.count = 0
              }
              
              console.log(element);
              type == 1
                ? (element.departName = element.departNameThree)
                : (element.departName = element.departNameFour);
              list.push(element);
            });

            console.log(list);
          }
          this.moreTotal = list;
          this.pageList();
          this.moreData.total = result.data.length;
          console.log(this.moreData.total);
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    //查询大区排名
    querySelect() {
      this.$store.dispatch("app/setLoading", true);
      // 数据请求
      let params = {
        dateType: this.timeType,
        startTime: this.startTime,
        pageSize: this.region.pageSize, //每页行数
        pageNum: this.region.pageNum, //页码
        sort: this.region.sort,
        personnelIdentity:this.personnelIdentity
      };

      this.axios
        .post("HOME_REGIONTOP", params)
        .then((result) => {
          console.log("查询返回", result);
          let list = [];
          if (result.data.length > 0) {
            console.log(result.data);

            result.data.forEach((element, index) => {
              if (
                index == 0 &&
                this.region.pageNum == 1 &&
                this.region.sort == "desc"
              ) {
                this.maxprecent = element.taxableAmount;
                console.log(this.maxprecent);
              }
              if(element.taxableAmount>0&&this.maxprecent){
                element.count =
                (element.taxableAmount / this.maxprecent).toFixed(2) * 100;
              }else{
                 element.count = 0
              }
              
              element.departName = element.departNameThree;
              list.push(element);
            });

            console.log(list);
          }
          this.testData = list.splice(0, 6);

          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    //查询地区排名
    querySelectDept() {
      this.$store.dispatch("app/setLoading", true);
      // 数据请求
      let params = {
        dateType: this.timeType,
        startTime: this.startTime,
        pageSize: this.dept.pageSize, //每页行数
        pageNum: this.dept.pageNum, //页码
        sort: this.dept.sort,
        personnelIdentity:this.personnelIdentity
      };

      this.axios
        .post("HOME_DEPT4TOP", params)
        .then((result) => {
          console.log("查询返回", result);

          let list = [];
          if (result.data.length > 0) {
            console.log(result.data);

            result.data.forEach((element, index) => {
              if (
                index == 0 &&
                this.dept.pageNum == 1 &&
                this.dept.sort == "desc"
              ) {
                this.maxprecent = element.taxableAmount;
                console.log(this.maxprecent);
              }
              if(element.taxableAmount>0&&this.maxprecent){
                element.count =
                (element.taxableAmount / this.maxprecent).toFixed(2) * 100;
              }else{
                 element.count = 0
              }
              element.departName = element.departNameFour;
              list.push(element);
            });

            console.log(list);
          }
          this.testData2 = list.splice(0, 6);
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },

    pageList() {
      let fiterData = JSON.parse(JSON.stringify(this.moreTotal));
      this.tableList = fiterData.filter(
        (item, index) =>
          index < this.moreData.currentPage * 10 &&
          index >= 10 * (this.moreData.currentPage - 1)
      );
      this.moreDatas = this.tableList;
    },
  },
};
</script>

<style scoped lang="scss">
.select-card {
  background: #f0f2f5;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .svgClass {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 50px;
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    .flexBox {
      flex: 1;
    }
    .close {
      // display: inline-block;
      width: 20px !important;
      padding-top: 2px;
      box-sizing: border-box;
      height: 100%;
      margin-left: 30px;
      display: flexbox;
      .el-icon-close {
        margin: auto;
      }
    }
    .right {
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #595959;
      line-height: 20px;
    }
  }
  .select-card-bottom {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #f0f2f5;
    // align-items: center;

    .block {
      width: 24px;
      height: 100%;
      background: #f0f2f5;
    }
  }
}
.select-card-bottom-left,
.select-card-bottom-right {
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  // min-height: 500px;
  .item {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 24px;
    box-sizing: border-box;
    align-content: center;
    min-height: 34px;
    .item-index {
      min-width: 22px;
      display: flex;
      margin-right: 12px;
      font-size: 16px;
      font-family: MiSans-Demibold;
      font-weight: 600;
      color: #262626;
      line-height: 22px;
      align-content: center;
    }
    .item-title {
      width: 96px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 22px;
      margin-right: 32px;
    }
    .item-last {
      margin-left: 19px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 1px;
      text-align: right;
      span {
        font-size: 12px;
        margin-left: 4px;
      }
      .one {
        color: #b57e14;
      }
      .two {
        color: #2b4c67;
      }
      .three {
        color: #5a3e1a;
      }
      .other {
        color: #595959;
      }
      .small {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 12px;
        margin-top: 4px;
      }
    }
  }
  .item-li {
    flex: 1;
    // display: flex;
    .count_pro {
      margin: auto;
    }
  }
}

:deep(.el-progress-bar__inner) {
  border-radius: none;
}

/deep/ .el-dialog__body {
  padding: 0;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  background: #fff;
  border-radius: 15px;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog {
     border-radius: 15px;
}
</style>