<template>
  <div class="select-card">
    <div class="select-card-top">
      <div>统计时间</div>
    </div>
    <div class="select-card-bottom">
      <div class="select-card-bottom-right">
        <el-select v-model="timeType" placeholder="请选择" size="small">
          <el-option
            v-for="item in dateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div v-show="timeType == 1">
          <el-date-picker
            class="select-day"
            style="margin-left: 24px; margin-right: 20px"
            format="yyyy-MM-dd"
            v-model="timeDay"
            type="date"
            align="right"
            unlink-panels
            placeholder="选择日期"
            size="small"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 2" class="week-box">
          <el-date-picker
            class="week-input1"
            style="margin-left: 20px; margin-right: 20px"
            v-model="timeWeekStart"
            type="week"
            :format="`yyyy 第 WW 周`"
            placeholder="选择开始周"
            size="small"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </div>
        <div v-show="timeType == 3">
          <el-date-picker
            class="select-day"
            style="margin-left: 20px; margin-right: 20px"
            format="yyyy-MM"
            v-model="timeMonth"
            type="month"
            align="right"
            placeholder="选择月份"
            size="small"
            :picker-options="pickerOptions3"
          >
          </el-date-picker>
        </div>
        <div style=" margin-right: 20px">
          <el-select v-model="personnelIdentity" placeholder="请选择" size="small" clearable multiple>
            <el-option
              v-for="item,key in personnelIdentityArr"
              :key="item"
              :label="item"
              :value="key"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" size="small" @click="querySelect"
          >查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Util from "@/utils/getMon";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      // 周
      pickerOptions2: {
        firstDayOfWeek: 1,
        disabledDate(date) {
          let day = Date.now();
          let limit = Util.getMon(day);
          let limitTime = new Date(limit);
          return date.getTime() > limitTime.getTime()+8.64e7;
          // return date.getTime() == Date.now();
        },
      },
      // 月
      pickerOptions3: {
        disabledDate(date) {
          let t = new Date().getDate();
          return date.getTime() > Date.now();
        },
      },
      dateOptions: [
        {
          value: 1,
          label: "日",
        },
        {
          value: 2,
          label: "周",
        },
        {
          value: 3,
          label: "月",
        },
      ],
      // 日
      select_data_tree: "", // 选中的数据维度权限
      data_tree: [], // 数据维度树
      productClass: [], // 产品类目列表
      selectClass: [], // 选中的类目
      timeType: 1, // 日期类型
      timeDay: "", // 选中日
      timeWeekStart: "", // 选中周
      timeWeekEnd: "",
      timeMonth: "", // 选中月
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      week1: "", // 本月第几周
      week2: "", // 本月第几周
      isLoading: false,
      random:0,
      personnelIdentity:"",
      personnelIdentityArr:{}
    };
  },
  
  created() {
    let curDate = new Date();
    this.timeDay = new Date(curDate.getTime());
    this.querySelect();
    this.axios.post("TRANSACTION_LIST", { type: 1 }).then((result) => {
      // console.log("获取交易概览选择列表", result);
      if (result.retCode == "200") {
        this.personnelIdentityArr =result.data.personnelIdentity;
      } else {
        this.$message.error(result.msg);
      }
      // 重新组装结构
    });
   
  },
  methods: {
    // 切换日期类型
    checkTimeType(index) {
      this.timeType = index;
      this.timeDay = "";
      this.timeWeekStart = "";
      this.timeWeekEnd = "";
      this.timeMonth = "";
      this.startTime = "";
      this.endTime = "";
      // 广播出去
      // Bus.$emit('overviewTimeType', this.timeType)
    },
    // 查询数据
    querySelect() {
      if (this.timeType == 1) {
        if (this.timeDay) {
          let startTime = new Date(this.timeDay).getTime();
          this.startTime = this.formatDate(startTime)
            .replace("-", "")
            .replace("-", "");
        } else {
          this.startTime = "";
        }
      } else if (this.timeType == 2) {
        if (this.timeWeekStart) {
          let moment = require("moment");
          this.startTime = moment(this.timeWeekStart)
            .utcOffset(480)
            .format("yyyyWW");
          let year1 = this.startTime.toString().slice(0, 4);
          let week1 = Number(this.startTime.toString().slice(4));
          this.startTime = year1 + week1;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 3) {
        if (this.timeMonth) {
          // 年
          let year0 = this.timeMonth.getFullYear();
          // 月
          let month0 = this.timeMonth.getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
        } else {
          this.startTime = "";
        }
      }
      console.log("开始时间", this.startTime);
      this.isLoading = true;
      this.$store.dispatch("app/setLoading", true);
      let params = {
        dateType: this.timeType,
        startTime: this.startTime,
        personnelIdentity:this. personnelIdentity.length?JSON.stringify(this. personnelIdentity):""
      };
      console.log({
        dateType: this.timeType,
        startTime: this.startTime,
      });
      this.$emit("transfer", params);
      // 数据请求
    },

    //当月累计数据
    getMonthData() {
      if (this.timeType == 1) {
        if (this.timeDay) {
          let startTime = new Date(this.timeDay).getTime();
          this.startTime = this.formatDate(startTime)
            .replace("-", "")
            .replace("-", "");
        } else {
          this.startTime = "";
        }
      } else if (this.timeType == 2) {
        if (this.timeWeekStart) {
          let moment = require("moment");
          this.startTime = moment(this.timeWeekStart)
            .utcOffset(480)
            .format("yyyyWW");
          let year1 = this.startTime.toString().slice(0, 4);
          let week1 = Number(this.startTime.toString().slice(4));
          this.startTime = year1 + week1;
        } else {
          this.startTime = "";
          this.endTime = "";
        }
      } else if (this.timeType == 3) {
        if (this.timeMonth) {
          // 年
          let year0 = this.timeMonth.getFullYear();
          // 月
          let month0 = this.timeMonth.getMonth() + 1;
          this.startTime = `${year0}${month0 < 10 ? "0" + month0 : month0}`;
        } else {
          this.startTime = "";
        }
      }
      console.log("开始时间", this.startTime);
      this.isLoading = true;
      this.$store.dispatch("app/setLoading", true);
      console.log({
        dateType: this.timeType,
        startTime: this.startTime,
      });
      // 数据请求
      this.axios
        .post("HOME_DEPTMONTHTOP", {
          dateType: this.timeType,
          startTime: this.startTime,
          personnelIdentity:this. personnelIdentity.length?JSON.stringify(this. personnelIdentity):""
        })
        .then((result) => {
          console.log("查询返回", result);
          // this.$store.dispatch('data/setTotalData', result.data)
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },

    formatDate(number) {
      var date = new Date(number);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
