<template>
  <div class="select-card">
    <div class="total-view">
      <div class="total-view-left">
        <div class="item">
          <div class="title title-total">
            <p>{{ total.title }}</p>
            <el-tooltip
              effect="dark"
              :content="total.remarks"
              placement="bottom"
              class='tipBlock'
            >
              <img src="@/assets/qs.png" />
            </el-tooltip>
          </div>

          <p class="num num-total">
            {{
              total.num
                ? String(total.num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "--"
            }}
          </p>
          <div class="compare compare-total">
            <p style="line-height:26px">较上一{{ date[transfer.dateType] }}</p>
            <p class="compare-con compare-con-tatol" v-if="total.rise > 0">
              <img class="question" src="@/assets/rise2.png" />{{
                total.rise
              }}%
            </p>
            <p class="compare-con drop compare-con-tatol" v-else>
              <img class="question" src="@/assets/drop2.png" />{{
                total.rise
              }}%
            </p>
          </div>
        </div>
      </div>
      <div class="total-view-right">
        <ul class="box box_4" ref="container">
          <li
            class="item"
            v-for="(item, i) in cardList"
            :key="i+'su23'"
            :style="{ backgroundColor: item.backColor }"
          >
           <div class="title">
            <p>{{ item.title }}</p>
            <el-tooltip
              effect="dark"
              :content="item.remarks"
              placement="bottom"
              class='tipBlock'
            >
              <img src="@/assets/qs.png" />
            </el-tooltip>
          </div>
            <p class="num">
              {{
                item.num
                  ? String(item.num).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "--"
              }}
            </p>
            <p class="compare">
              较上一{{ date[transfer.dateType] }}
              <span class="compare-con" v-if="item.rise == 1">
                <!-- <img class="question" src="@/assets/rise2.png" /> -->
                0%
              </span>
              <span class="compare-con drop" v-else>
                <!-- <img class="question" src="@/assets/drop2.png" /> -->
                0%
              </span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["transfer"],
  data() {
    return {
      date: {
        1: "日",
        2: "周",
        3: "月",
      },
      total: {
        id: 1,
        icon: "icon1",
        title: "销售收入（万）",
        num: 12000.5,
        unit: "万",
        remarks:
          "统计周期内，对app支付的订单金额和月结订单金额求和，剔除赠送单、快捷支付订单以及当天退款的订单。", // 备注
        backColor: "#CAE5EE", // 线条颜色
        rise: 1,
      },
      cardList: [
        {
          id: 1,
          icon: "icon1",
          title: "销售成本（万）",
          num: 0,
          unit: "万",
          remarks:
            "统计周期内，对app支付的订单金额和月结订单金额求和，剔除赠送单、快捷支付订单以及当天退款的订单。", // 备注
          backColor: "#CAE5EE", // 线条颜色
          rise: 0,
        },
        {
          id: 2,
          icon: "icon1",
          title: "销售费用（万）",
          num: 0,
          unit: "万",
          remarks:
            "统计周期内，对app支付的订单金额和月结订单金额求和，剔除赠送单、快捷支付订单以及当天退款的订单。", // 备注
          backColor: "#CAE5EE", // 线条颜色
          rise: 0,
        },
        {
          id: 3,
          icon: "icon1",
          title: "销售毛利（万）",
          num: 0,
          unit: "万",
          remarks:
            "统计周期内，对app支付的订单金额和月结订单金额求和，剔除赠送单、快捷支付订单以及当天退款的订单。", // 备注
          backColor: "#DADCEE", // 线条颜色
          rise: 0,
        },
        {
          id: 4,
          icon: "icon1",
          title: "销售利润（万）",
          num: 0,
          unit: "万",
          remarks:
            "统计周期内，对app支付的订单金额和月结订单金额求和，剔除赠送单、快捷支付订单以及当天退款的订单。", // 备注
          backColor: "#D4DBEA", // 线条颜色
          rise: 0,
        },
      ],
    };
  },
  created() {
    console.log(this.transfer);
    this.querySelect();
  },
  methods: {
    querySelect() {
      this.axios
        .post("HOME_TRANSACTION", {
          dateType: this.transfer.dateType,
          startTime: this.transfer.startTime,
          personnelIdentity:this.transfer.personnelIdentity
        })
        .then((result) => {
          console.log("查询返回", result);
          this.total.num = result.data.jsonSale.totalSales;
          this.total.rise = result.data.jsonSale.totalSalesRadio;

          // this.$store.dispatch('data/setTotalData', result.data)
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>


<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .total-view {
    display: flex;
    padding: 24px;
    box-sizing: border-box;
    .total-view-left {
      width:33%;
      height: 325px;
      background: linear-gradient(39deg, #26c686 0%, #21c87f 100%);
      border-radius: 8px;
      color: #fff;
    }
    .item {
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      position: relative;
      border-radius: 8px;
      padding: 24px;
      box-sizing: border-box;

      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #595959;
        // line-height: 22px;
        display: flex;
        .tipBlock {
          width: 16px;
          height: 16px;
          margin-left: 3px;
        }
      }
      .num {
        font-size: 32px;
        font-family: MiSans-Demibold;
        font-weight: 600;
        color: #262626;
        line-height: 32px;
        letter-spacing: 1px;
        margin-top: 24px;
      }
      .compare {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        // line-height: 22px;
      }
      .compare-con {
        width: 47px;
        height: 18px;
        background: #ffffff;
        border-radius: 4px;
        opacity: 0.9;
        padding: 4px;
        box-sizing: border-box;
        color: #ff5322;
        img {
          display: inline;
          width: 10px;
          height: 10px;
          margin-right: 4px;
          margin-top: 4px;
        }
      }
      .drop {
        color: #00a4ff;
      }
      .title-total {
        color: #fff;
      }
      .num-total {
        color: #fff;
        font-size: 48px;
        font-family: MiSans-Demibold;
        font-weight: 600;
        color: #ffffff;
        line-height: 48px;
        letter-spacing: 1px;
        margin-top: 40px;
        margin-bottom: 118px;
      }
      .compare-total {
        color: #fff;
        display: flex;
        .compare-con-tatol {
          font-size: 16px;
          width: auto;
          height: 26px;
          margin-left: 9px;
          display: flex;
          img {
          display: inline;
          width: 14px;
          height: 14px;
          margin-right: 4px;
          margin-top: 2px;
        }
        }
      }
    }
    .total-view-right {
      flex: 1;
      padding-left: 12px;
      box-sizing: border-box;
      .box {
        display: grid;
        box-sizing: border-box;
        height: 100%;
        padding: 0;
        width: 100%;
        margin: 0;
      }
      .box_4 {
        //四宫格
        grid-template-columns: repeat(2, 49%); //2列，各占50%
        grid-template-rows: repeat(2, 48%); //2行，各占50%
        grid-column-gap: 12px;
        grid-row-gap: 12px;
      }
    }
  }
}
@media screen and (min-width:2000px) {
    .item {
        border-radius: 15px;
    }
    .box_4 {
        //四宫格
        grid-template-columns: repeat(2, 49%); //2列，各占50%
        grid-template-rows: repeat(2, 48%); //2行，各占50%
        grid-column-gap: 12px;
        grid-row-gap: 12px;
      }
}
@media screen and (min-width:1900px) {
    
   .select-card .total-view .total-view-right .box_4{
        //四宫格
        grid-template-columns: repeat(2, 49%); //2列，各占50%
        grid-template-rows: repeat(2, 48%); //2行，各占50%
        grid-column-gap: 22px;
        grid-row-gap: 12px;
      }
}
</style>